import * as CookieConsent from "vanilla-cookieconsent";

const revision = 3

const cc = CookieConsent

window.cc = cc

cc.run({
    mode: 'opt-in',
    revision,
    guiOptions: {
        consentModal: {
            layout: 'box wide',
            position: 'middle center',
        }
    },

    disablePageInteraction: true,

    onFirstConsent: () => {
        logConsent()
    },

    onChange: () => {
        logConsent()
    },

    onConsent: function () {
        if (cc.acceptedService('adsbygoogle', 'marketing')) {
            cc.loadScript('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5670222138716416')
                .then(() => {
                    (adsbygoogle = window.adsbygoogle || []).push({})
                })
                .catch((e) => console.log('Script failed to load!', e));
        }
    },

    categories: {
        necessary: {
            enabled: true,  // this category is enabled by default
            readOnly: true  // this category cannot be disabled
        },
        analytics: {
            enabled: false,
            readOnly: false,
            services: {
                matomo: {
                    label: 'Matomo',
                },
                sentry_io: {
                    label: 'Sentry.io',
                },
            },
        },
        marketing: {
            enabled: false,
            readOnly: false,

            services: {
                adsbygoogle: {
                    label: 'adsbygoogle',
                },
            },
        }
    },
    language: {
        default: 'de',

        translations: {
            de: {
                consentModal: {
                    title: 'Wir nutzen Cookies',
                    description: 'Um unsere Webseite für dich optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies.',
                    acceptAllBtn: 'Alle akzeptieren',
                    acceptNecessaryBtn: 'Alle ablehnen',
                    showPreferencesBtn: 'Individuelle Konfiguration'
                },
                preferencesModal: {
                    title: 'Cookie Einstellungen',
                    acceptAllBtn: 'Alle akzeptieren',
                    acceptNecessaryBtn: 'Alle ablehnen',
                    savePreferencesBtn: 'Aktuelle Einstellungen speichern',
                    closeIconLabel: 'Cookie Einstellungen schließen',
                    sections: [
                        {
                            title: "Cookie usage",
                            description: 'Wir verwenden Cookies, um die grundlegenden Funktionen der Website zu gewährleisten und um dein Online-Erlebnis zu verbessern. Du kannst für jede Kategorie wählen, ob du diese an- oder abmelden möchten.'
                        }, {
                            title: 'Erforderliche Cookies',
                            description: 'Diese Dienste sind für die korrekte Funktion dieser Website unerlässlich. Sie können nicht deaktiviert werden, da die Website sonst nicht richtig funktionieren würde.',
                            linkedCategory: 'necessary'
                        }, {
                            title: 'Reichweitenmessung',
                            description: 'Diese Dienste sind erforderlich, um anonyme Besucherstatistiken zu erfassen, die uns dabei unterstützen, die Nutzung unserer Internetseite zu verstehen.',
                            linkedCategory: 'analytics'
                        }, {
                            title: 'Marketing',
                            description: '',
                            linkedCategory: 'marketing'
                        }, {
                            title: 'Mehr Informationen',
                            description: 'Informationen zur Datenverarbeitung findest du in der <a href="/de/service/datenschutzerklaerung">Datenschutzerklärung</a>.'
                        }
                    ]
                }
            }
        }
    }
})

function logConsent() {
    const cookie = cc.getCookie();
    const preferences = cc.getUserPreferences();

    const userConsent = {
        consentId: cookie.consentId,
        acceptType: preferences.acceptType,
        acceptedCategories: preferences.acceptedCategories,
        rejectedCategories: preferences.rejectedCategories,
        revision,
    };

    fetch('/api/cookie-consent', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json, text-plain, */*",
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify(userConsent)
    })
}