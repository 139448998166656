import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'
import Confetti from 'canvas-confetti'
import "./modules/CookieConsent";
import sentry from './modules/Sentry';

window.addEventListener('start-confetti', () => {
    Confetti();
})

import.meta.glob([
    '../images/**',
]);

window.Alpine = Alpine

window.addEventListener('meldungen.load-more', event => {
    if (typeof adsbygoogle != 'undefined') {
        setTimeout(() => {
            (adsbygoogle = window.adsbygoogle || []).push({})
        }, 500)
    } else {
        console.info('adsbygoogle nicht initiiert')
    }
})

Alpine.plugin(persist)
Alpine.start()

if (cc.acceptedService('sentry_io', 'analytics')) {
    const sentry = await import('./modules/Sentry')
    sentry()
}